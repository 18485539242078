import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { Formik } from 'formik';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  userSignOut
} from 'store/actions/Auth';
import {SigninSchema} from '../validationSchemas';
import { Form } from './form';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      phone: '',
      password: ''
    };
  }

  componentDidMount() {
    this.props.userSignOut();
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/profile');
    }
  }

  submitValues = ({ phone, password }) => {
    this.props.showAuthLoader();
    this.props.userSignIn({phone, password});
  };

  render() {
    const {
      phone,
      password
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    const values = {
      phone,
      password
    };
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="LobiBox">
              <img src={require('assets/images/LB-Logo.png')} alt="LobiBox" title="LobiBox" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.loginHeader" /></h1>
            </div>

            <div className="app-login-form">
              <Formik
                render={props => <Form {...props} />}
                initialValues={values}
                validationSchema={SigninSchema}
                onSubmit={this.submitValues}
              />
            </div>
          </div>

        </div>
        {
          loader
          && (
          <div className="loader-view">
            <CircularProgress />
          </div>
          )
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    loader, alertMessage, showMessage, authUser
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser
  };
};

export default connect(mapStateToProps, {
  userSignIn,
  userSignOut,
  hideMessage,
  showAuthLoader
})(SignIn);
