import {
  all, call, fork, put, takeEvery, takeLatest
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { catchError } from 'services/catchError';
import { composeErrorMessage } from 'util/helpers';
import {
  SIGNIN_USER,
  RESET_PASSWORD,
  PASSWORD_CONFIRMATION,
  FETCH_USER_INFO,
  UPDATE_USER_INFO
} from '../ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userGotResetPasswordMessage,
  userResetPasswordSuccess,
  fetchUserInfoSuccess
} from '../actions/Auth';
import API from '../../services/api';


const signInUserWithPhonePasswordRequest = async (phone, password) => API.post('user/token/', {
  phone,
  password
})
  .then(authUser => authUser.data)
  .catch((error) => {
    return error.response;
  });

function* signInUserWithPhonePassword({payload}) {
  const {phone, password} = payload;
  try {
    const signInUser = yield call(signInUserWithPhonePasswordRequest, phone, password);
    if (!signInUser.hasOwnProperty('token')) {
      if (signInUser.hasOwnProperty('data')) {
        yield put(showAuthMessage(composeErrorMessage(signInUser.data)));
      } else {
        yield put(showAuthMessage('fail login'));
      }
    } else {
      localStorage.setItem('token', signInUser.token);
      yield put(userSignInSuccess(signInUser.token));
    }
  } catch (error) {
    yield put(showAuthMessage('fail login'));
  }
}

const resetPasswordRequest = async phone => API.post('user/password/recovery/', { phone })
  .then(resp => resp.data)
  .catch(error => error.response.data);

function* resetPassword({ payload }) {
  const { phone } = payload;

  try {
    const resetEmailResp = yield call(resetPasswordRequest, phone);
    if (resetEmailResp.hasOwnProperty('detail')) {
      yield put(userGotResetPasswordMessage(resetEmailResp.detail));
      yield put(push('/forgot_password_done'));
    } else if (resetEmailResp.hasOwnProperty('phone') && Array.isArray(resetEmailResp.phone)) {
      yield put(showAuthMessage(resetEmailResp.phone[0]));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const confirmPasswordRequest = async (password, passwordConfirm, uid, token) => API.post('user/password/recovery/confirm/',
  {
    uid,
    token,
    new_password1: password,
    new_password2: passwordConfirm
  })
  .then(resp => resp.data)
  .catch(catchError);

const fetchUserRequest = async () => API.get('user/me/')
  .then(resp => resp.data)
  .catch(catchError);

const updateUserRequest = async (email, firstName, lastName) => API.patch('user/me/', {
  email,
  first_name: firstName,
  last_name: lastName
})
  .then(resp => resp.data)
  .catch(catchError);

function* confirmPassword({payload}) {
  const {
    password, passwordConfirm, uid, token
  } = payload;

  try {
    const resetPasswordResp = yield call(confirmPasswordRequest, password, passwordConfirm, uid, token);
    if (resetPasswordResp) {
      if (resetPasswordResp.hasOwnProperty('detail')) {
        yield put(userResetPasswordSuccess(resetPasswordResp.detail));
        yield put(push('/reset/done/'));
      } else if (resetPasswordResp.hasOwnProperty('new_password2') && Array.isArray(resetPasswordResp.new_password2)) {
        yield put(showAuthMessage(resetPasswordResp.new_password2[0]));
      } else if (resetPasswordResp.hasOwnProperty('non_field_errors') && Array.isArray(resetPasswordResp.non_field_errors)) {
        yield put(push('/signin/'));
        yield put(showAuthMessage(resetPasswordResp.non_field_errors[0]));
      }
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }

}

function* fetchUser() {
  try {
    const user = yield call(fetchUserRequest);
    if (Object.prototype.hasOwnProperty.call(user, 'email', 'first_name', 'last_name')) {
      yield put(fetchUserInfoSuccess(user));
    }
  } catch (error) {
    console.error(error);
  }
}

function* updateUser({payload}) {
  const { email, firstName, lastName } = payload;
  try {
    const user = yield call(updateUserRequest, email, firstName, lastName);
    if (Object.prototype.hasOwnProperty.call(user, 'email', 'first_name', 'last_name')) {
      yield put(fetchUserInfoSuccess(user));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithPhonePassword);
}

export function* resetUserPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

export function* confirmUserPassword() {
  yield takeEvery(PASSWORD_CONFIRMATION, confirmPassword);
}

export function* fetchUserInfo() {
  yield takeLatest(FETCH_USER_INFO, fetchUser);
}

export function* updateUserInfo() {
  yield takeEvery(UPDATE_USER_INFO, updateUser);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(resetUserPassword),
    fork(confirmUserPassword),
    fork(fetchUserInfo),
    fork(updateUserInfo)]);
}
