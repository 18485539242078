import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { Formik } from 'formik';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  userResetPassword
} from 'store/actions/Auth';
import {ForgotPasswordSchema} from '../validationSchemas';
import { Form } from './form';

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      phone: ''
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  submitValues = ({ phone }) => {
    this.props.showAuthLoader();
    this.props.userResetPassword({phone});
  };

  render() {
    const {
      phone
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    const values = {
      phone
    };
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Jambo">
              <img src={require('assets/images/LB-Logo.png')} alt="LobiBox" title="LobiBox" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1><IntlMessages id="appModule.resetPasswordHeader" /></h1>
            </div>

            <div className="app-login-form">
              <Formik
                render={props => <Form {...props} />}
                initialValues={values}
                validationSchema={ForgotPasswordSchema}
                onSubmit={this.submitValues}
              />
            </div>
          </div>

        </div>
        {
          loader
          && (
          <div className="loader-view">
            <CircularProgress />
          </div>
          )
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    loader, alertMessage, showMessage, authUser, infoMessage
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser, infoMessage
  };
};

export default connect(mapStateToProps, {
  userResetPassword,
  hideMessage,
  showAuthLoader,
})(ForgotPassword);
