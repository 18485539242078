import React from 'react';
import {TextField, Button} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import { Link } from "react-router-dom";

export const Form = (props) => {
  const {
    values: { phone },
    errors,
    touched,
    handleSubmit,
    handleChange,
    isValid,
    setFieldTouched
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <TextField
          id="phone"
          name="phone"
          helperText={touched.phone ? errors.phone : ''}
          error={touched.phone && Boolean(errors.phone)}
          label="Phone"
          fullWidth
          value={phone}
          onChange={change.bind(null, 'phone')}
          margin="normal"
          className="mt-1 my-sm-3"
        />
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}>
            <IntlMessages id="appModule.resetPasswordButton" />
          </Button>
        </div>
        <Link to="/signin">
          Back To Login
        </Link>
      </fieldset>
    </form>
  );
};
