export const CONTACT_ADMINISTRATOR_MESSAGE = 'Please contact your system administrator.';
export const UNKNOWN_SERVER_ERROR_MESSAGE = 'Unknown Server Error';
export const IS_REQUIRED_FIELD = 'This field is required';
export const VALID_EMAIL = 'Enter a valid email address';
export const NOT_EMPTY = 'Not Empty';
export const SHOULD_BE_FILLED = 'Should be filled';
export const VALID_PASSWORD = 'Password should contain at least 1 upper case letter, 1 lower case letter, 1 special character, 1 number';
export const VALID_PASSWORD_LENGTH = 'Password should contain at least 8 characters';
export const PASSWORDS_NOT_MATCH = 'The two password fields didn\'t match';
export const VALID_PHONE_LENGTH = 'Phone must not contain more than 32 characters';
