import React from 'react';
import { Breadcrumb } from 'reactstrap';
import { Link } from 'react-router-dom';
import IntlMessages from '../util/IntlMessages';

class ResetPasswordDone extends React.Component {

  render() {
    return (
      <div className="app-wrapper">
        <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
          <h2 className="title mb-3 mb-sm-0">
            <IntlMessages id="pages.commonHeader" />
          </h2>
          <Breadcrumb className="mb-0" tag="nav">
            <Link className="jr-list-link" to="/signin/">Log in</Link>
          </Breadcrumb>
        </div>
        <div className="d-flex justify-content-center">
          <h1><IntlMessages id="appModule.resetPasswordDone.header" /></h1>
        </div>
        <div className="d-flex justify-content-center">
          <h4><IntlMessages id="appModule.resetPasswordDone.description" /></h4>
        </div>
      </div>
    );
  }
}

export default ResetPasswordDone;
