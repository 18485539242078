import { NotificationManager } from 'react-notifications';
import { composeErrorMessage } from 'util/helpers';

const handleUnauthorized = (error, props) => {
  props.history.push({
    pathname: '/signin',
    state: { alertMessage: error.detail || 'Unauthorized' }
  });
};

export const catchError = (error, props) => {
  console.error(error.response, 'catch error');
  if (((Object.prototype.hasOwnProperty.call(error, 'status')
    && error.status === 401)
    || (Object.prototype.hasOwnProperty.call(error.response, 'status')
      && error.response.status === 401)) && props) {
    handleUnauthorized(error.response.data, props);
    return;
  }
  if (!error.response) {
    NotificationManager.error('Unknown Server Error');
    return;
  }
  if (((Object.prototype.hasOwnProperty.call(error, 'status')
    && error.status === 404)
    || (Object.prototype.hasOwnProperty.call(error.response, 'status')
      && error.response.status === 404)) && props) {
    props.history.push('/app/notfoundpage');
    return;
  }
  if (Object.prototype.hasOwnProperty.call(error.response.data, 'detail')
    && error.response.data.detail) {
    NotificationManager.error(error.response.data.detail);
  } else {
    NotificationManager.error(composeErrorMessage(error.response.data), '', 5000);
  }
};
