import React from 'react';
import IntlMessages from 'util/IntlMessages';
import {
  Button,
  Input,
  InputAdornment,
  IconButton,
  InputLabel
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';

class Form extends React.Component {
  constructor() {
    super();
    this.state = {
      showPassword: false,
      showPasswordConfirm: false
    };
  }

  handleClickShowPassword = prop => () => {
    this.setState({[prop]: !this.state[prop]});
  };

  change = (name, e) => {
    const {
      handleChange,
      setFieldTouched
    } = this.props;
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  render() {
    const {
      values: {
        password,
        passwordConfirm
      },
      errors,
      touched,
      handleSubmit,
      isValid,
    } = this.props;
    const {
      showPassword,
      showPasswordConfirm
    } = this.state;
    return (
      <form onSubmit={handleSubmit}>
        <InputLabel htmlFor="adornment-password">Password</InputLabel>
        <Input
          id="adornment-password"
          error={touched.password && Boolean(errors.password)}
          type={showPassword ? 'text' : 'password'}
          fullWidth
          className="mt-0 mb-4"
          name="password"
          value={password}
          onChange={this.change.bind(null, 'password')}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword('showPassword')}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />
        <p className="error-message">
          {touched.password ? errors.password : ''}
        </p>
        <InputLabel htmlFor="adornment-confirm-password">Confirm Password</InputLabel>
        <Input
          id="adornment-confirm-password"
          error={touched.passwordConfirm && Boolean(errors.passwordConfirm)}
          type={showPasswordConfirm ? 'text' : 'password'}
          fullWidth
          className="mt-0 mb-4"
          name="passwordConfirm"
          value={passwordConfirm}
          onChange={this.change.bind(null, 'passwordConfirm')}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword('showPasswordConfirm')}
              >
                {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />
        <p className="error-message">
          {touched.passwordConfirm ? errors.passwordConfirm : ''}
        </p>
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}>
            <IntlMessages id="appModule.ResetPassword.change" />
          </Button>
        </div>
      </form>
    );
  }
}

export default Form;
