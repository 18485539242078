import { UNKNOWN_SERVER_ERROR_MESSAGE } from 'constants/messages';
import moment from 'moment';

export const composeErrorMessage = (messageObject) => {
  let result = '';

  if (typeof messageObject !== 'object') {
    return UNKNOWN_SERVER_ERROR_MESSAGE;
  }
  const objKeysArray = Object.keys(messageObject);

  objKeysArray.forEach((item) => {
    messageObject[item].forEach((message, i) => {
      result += `${item} : ${messageObject[item][i]} \n`;
    });
  });
  return result;
};

export const scrollToRef = (ref) => {
  setTimeout(() => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  }, 500);
};

export const composeTimestamp = timestamp => moment(timestamp).format('LLL');

export const composeVuFullName = vu => `${vu.name} (${vu.building}, ${vu.company})`;

export const composeTenantFullName = (tenant) => {
  if (!tenant) {
    return 'Not Found';
  }
  const firstName = tenant.first_name || '';
  const lastName = tenant.last_name || '';
  return `${firstName} ${lastName}`;
};

export const composeVuForLookup = (availableVu) => {
  const vuForLookUp = {};

  availableVu.forEach((item) => {
    vuForLookUp[item.id] = item.name;
  });
  return vuForLookUp;
};

export const composeTenantsForLookup = (availableTenants) => {
  const tenantsForLookUp = {};

  availableTenants.forEach((item) => {
    tenantsForLookUp[item.id] = `${item.first_name} ${item.last_name}`;
  });
  return tenantsForLookUp;
};

export const downloadFile = (data, fileName) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const composeEventsChain = events => events.map((evt, i) => ((events.length === i + 1) ? evt.type : `${evt.type} - `));
