import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { isIOS, isMobile } from 'react-device-detect';

import Header from 'components/Header/index';
import {
  COLLAPSED_DRAWER,
  FIXED_DRAWER
} from 'store/ActionTypes';
import asyncComponent from '../util/asyncComponent';

class App extends React.Component {

  render() {
    const {
      match, drawerType, navigationStyle
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    // set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        <NotificationContainer />
        <div className="app-main-container">
          <Header />
          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route
                  exact
                  path={`${match.url}`}
                  component={asyncComponent(() => import('./routes/profile'))} />
                <Route
                  exact
                  path={`${match.url}/password_change`}
                  component={asyncComponent(() => import('./routes/passwordChange'))} />
                <Route
                  exact
                  path={`${match.url}/confirm-email/:uid/:token`}
                  component={asyncComponent(() => import('./routes/confirmEmail'))} />
                <Route component={asyncComponent(() => import('components/Error404'))} />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({settings}) => {
  const {drawerType, navigationStyle, horizontalNavPosition} = settings;
  return {drawerType, navigationStyle, horizontalNavPosition};
};
export default withRouter(connect(mapStateToProps)(App));
