import React from 'react';
import * as Yup from 'yup';
import {
  IS_REQUIRED_FIELD,
  VALID_PASSWORD,
  VALID_PASSWORD_LENGTH,
  PASSWORDS_NOT_MATCH,
  VALID_PHONE_LENGTH
} from 'constants/messages';
import {
  TENANT_PASSWORD
} from 'constants/regExp';

export const SigninSchema = Yup.object().shape({
  phone: Yup.string()
    .max(32, VALID_PHONE_LENGTH)
    .required(IS_REQUIRED_FIELD),
  password: Yup.string()
    .min(8, VALID_PASSWORD_LENGTH)
    .required(IS_REQUIRED_FIELD)
});

export const ForgotPasswordSchema = Yup.object().shape({
  phone: Yup.string()
    .max(32, VALID_PHONE_LENGTH)
    .required(IS_REQUIRED_FIELD)
});

export const PasswordRecoverySchema = Yup.object().shape({
  password: Yup.string()
    .min(8, VALID_PASSWORD_LENGTH)
    .matches(TENANT_PASSWORD, VALID_PASSWORD)
    .required(IS_REQUIRED_FIELD),
  passwordConfirm: Yup.string()
    .min(8, VALID_PASSWORD_LENGTH)
    .matches(TENANT_PASSWORD, VALID_PASSWORD)
    .required(IS_REQUIRED_FIELD)
    .oneOf([Yup.ref('password')], PASSWORDS_NOT_MATCH)
});
