import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import { Formik } from 'formik';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  hideMessage,
  showAuthLoader,
  passwordConfirmation,
  userSignOut
} from 'store/actions/Auth';
import {PasswordRecoverySchema} from '../validationSchemas';
import Form from './form';

class PasswordRecovery extends React.Component {
  constructor() {
    super();
    this.state = {
      password: '',
      passwordConfirm: '',
      showPassword: false,
      showPasswordConfirm: false
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    this.props.userSignOut();
  }

  submitValues = ({ password, passwordConfirm }) => {
    const {uid} = this.props.match.params;
    const {token} = this.props.match.params;

    this.props.showAuthLoader();
    this.props.passwordConfirmation({
      password, passwordConfirm, uid, token
    });
  };

  render() {
    const {
      password,
      passwordConfirm,
      showPassword,
      showPasswordConfirm
    } = this.state;
    const {showMessage, loader, alertMessage} = this.props;
    const values = {
      password,
      passwordConfirm,
      showPassword,
      showPasswordConfirm
    };
    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="LobiBox">
              <img src={require('assets/images/LB-Logo.png')} alt="LobiBox" title="LobiBox" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header">
              <h1>Password setup confirmation</h1>
            </div>

            <div className="mb-4">
              <h2><IntlMessages id="appModule.ResetPassword.header" /></h2>
            </div>

            <div className="mb-4">
              <h4><IntlMessages id="appModule.ResetPassword.description" /></h4>
            </div>

            <div className="app-login-form">
              <Formik
                render={props => <Form {...props} />}
                initialValues={values}
                validationSchema={PasswordRecoverySchema}
                onSubmit={this.submitValues}
              />
            </div>
          </div>

        </div>
        {
          loader
          && (
          <div className="loader-view">
            <CircularProgress />
          </div>
          )
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {
    loader, alertMessage, showMessage, authUser, infoMessage
  } = auth;
  return {
    loader, alertMessage, showMessage, authUser, infoMessage
  };
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  passwordConfirmation,
  userSignOut
})(PasswordRecovery);
