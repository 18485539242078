import React from 'react';
import {Link} from 'react-router-dom';

const Header = () => (
  <header className="app-header">
    <Link className="app-logo ml-3 d-sm-block" to="/">
      <img src={require('assets/images/LobiBox-Logo.png')} alt="LobiBox" title="LobiBox" />
    </Link>
    <div className="toolbar-left">
      <Link className="mr-4" to="/profile/password_change">
            Change password
      </Link>
      <Link className="mr-4" to="/signin">
            LOGOUT
      </Link>
    </div>
  </header>
);

export default Header;
